<template>
  <div class="success-wrap">
    <NavBack/>
    <div class="sticky-header">
      <MenuAndLogo></MenuAndLogo>
    </div>
    <div class="has-padding">
      <div
        class="page-title has-text-weight-semibold has-text-centered"
        data-testid="page-title"
      >{{membershipType === 'accredited' ? 'Accredited Member' : 'Become a member'}}</div>
      <div class="main has-background-white has-text-centered">
        <div class="section-title has-text-weight-semibold">You’re officially an addy member!</div>
        <div class="section-content">Congrats & welcome to our community!</div>
        <div class="section-content">This is your all-access pass to everything that we offer.</div>
        <img src="@assets/images/membership/membership-success.png" alt="success" class="success-image">
        <div class="welcome-text">We can't wait to #InvestTogether! 😊</div>
      </div>
      <div class="bottom-button has-text-right">
        <button
          class="button-finish button is-primary is-rounded is-medium-height has-text-weight-bold"
          data-testid="finish-btn"
          @click="toEntryPage"
        >Finish</button>
      </div>
    </div>
    <lottie-player
      class="confetti"
      style="width: 300px; height: 220px;"
      autoplay
      loop
      mode="normal"
      src="https://assets10.lottiefiles.com/packages/lf20_wrcc9v6e.json"
    >
    </lottie-player>
  </div>
</template>
<script>
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import NavBack from '@components/desktop/header-nav-back.vue'
import { mapState } from 'vuex'
import '@lottiefiles/lottie-player'
import { embeddedInIos } from '@utils/common-methods/common'

export default {
  components: {
    MenuAndLogo,
    NavBack,
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
    returnUrl() {
      return decodeURIComponent(this.$route.query.returnUrl)
    },
    membershipType() {
      return this.$route.query.type
    },
  },
  mounted() {
    this.mixpanel.track('kjb5kyhq')
  },
  methods: {
    toEntryPage() {
      if (embeddedInIos()) return window.webkit.messageHandlers.closeWebview.postMessage(true)
      this.$router.push(this.returnUrl)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';
.success-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  overflow: auto;
  padding-bottom: 80px;
  .has-padding {
    padding: 0 27px;
    .page-title {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 16px;
      letter-spacing: 0.03em;
    }
    .main {
      height: 350px;
      border-radius: 12px;
      box-shadow: 0px 30px 50px rgba(215, 145, 51, 0.11);
      margin-bottom: 30px;
      padding: 0 14px;
      .section-title {
        font-size: 16px;
        padding-top: 22px;
        padding-bottom: 8px;
      }
      .section-content {
        font-size: 16px;
        line-height: 19px;
        padding: 0 8px;
      }
      .success-image {
        width: 126px;
        margin-top: 45px;
      }
      .welcome-text {
        margin-top: 30px;
        font-size: 16px;
      }
    }
    .button-finish {
      width: 108px;
    }
  }
  .confetti {
    position: absolute;
    top: 220px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 200;
  }
}
@media only screen and (min-width: $min-viewport-width) {
  .success-wrap {
    padding-top: 19px;
    min-height: $min-height-mobile-container;
    max-width: $mobile-wrapper-width;
    position: unset;
  }
}
</style>
